<template>
  <b-button
    variant="success"
    href="https://wa.me/+553140424391?text=Olá preciso de ajuda com meu mapa"
    target="_blank"
    style="
      border: 0px;
      border-radius: 15%;
      position: fixed;
      right: 45px;
      bottom: 68px;
      z-index: 1;
    "
  >
    <i class="fa fa-whatsapp fa-2x" aria-hidden="true"></i>
  </b-button>
</template>

<script>
export default {
  name: "WhatsappButton",
}
</script>

<style scoped>
</style>
