import { render, staticRenderFns } from "./WhatsappButton.vue?vue&type=template&id=3ffe00b6&scoped=true"
import script from "./WhatsappButton.vue?vue&type=script&lang=js"
export * from "./WhatsappButton.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ffe00b6",
  null
  
)

export default component.exports