import axios from 'axios'
import {
  mapActions
} from 'vuex'
import {
  errorMessage,
  closeAllPopupBus
} from '../components/MessageBus'
import router from '../router'

export const HTTP = axios.create({})

export const setTokenHeader = (token) => {
  if (HTTP) {
    HTTP.defaults.headers.common['validator'] = token
  }
}

export const getExported = async (url, endLoading, notStartLoading) => {
  try {
    const response = await HTTP.get(url)
    return response.data
  } catch (error) {
    console.error('Error during request:', error)
    throw error
  }
}

const showRequestErrorMessage = (message, show) => {
  if (typeof show == 'undefined')
    errorMessage(message);
}

export default {
  methods: {
    ...mapActions({
      startLoading: 'startLoading',
      endLoading: 'endLoading',
      logout: 'logout'
    }),
    baseRequest(method, url, data, endLoading, notShowErrorMessage, startLoading) {
      if (!startLoading)
        this.startLoading();
      return new Promise((resolve, reject) => {
        HTTP.request({
          method,
          url,
          data
        }).then(response => {
          // console.log('REQUEST',response, response.data)
          resolve(response.data)
          if (typeof endLoading == 'undefined' || endLoading) {
            this.endLoading()
          }
        }).catch(error => {
          if (typeof notShowErrorMessage == 'undefined')
            this.endLoading()
          if (error.response) {
            // console.log(error.response.data)

            if (error.response.status === 405 || error.response.status === 500) {
              // if (error.response.data.errorCode === 3) {
              //   closeAllPopupBus();
              //   router.go('/login');
              //   showRequestErrorMessage('Seu login expirou', notShowErrorMessage)
              // } else

              if (error.response.data.errorMessage) {
                showRequestErrorMessage(error.response.data.errorMessage, notShowErrorMessage)
              } else {
                showRequestErrorMessage('Ocorreu um error inesperado', notShowErrorMessage)
              }

            } else if (error.response.status === 504) {
              closeAllPopupBus();
              router.go('/login');
              showRequestErrorMessage('Ocorreu um erro na comunicação em breve será restaurado', notShowErrorMessage)
            } else if (error.response.status === 401) {
              closeAllPopupBus();
              router.go('/login');
            } else if (error.response.data.errorMessage) {
              showRequestErrorMessage(error.response.data.errorMessage, notShowErrorMessage)
            }
          } else if (error.request) {
            showRequestErrorMessage(error.request, notShowErrorMessage)
            console.log(error.request);
          } else {
            console.log('Error', error.message)
            showRequestErrorMessage(error.message, notShowErrorMessage)
          }
          reject(error)
        })
      })
    },
    post(url, data, endLoading) {
      return this.baseRequest('post', url, data, endLoading);
    },
    get(url, endLoading = true, notStartLoading) {
      return this.baseRequest('get', url, null, endLoading, null, notStartLoading);
    },
    put(url, data) {
      return this.baseRequest('put', url, data);
    },
    delete(url) {
      return this.baseRequest('delete', url);
    }
  }
}
