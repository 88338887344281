<template>
  <TemplatePopup :fullScreen="fullScreen">
    <div slot="header" >
      Elementos Selecionados
    </div>
    <div slot="body">
      <div class="table-container">
        <table class="elements-table">
          <thead>
            <tr>
              <th class="type-column">Tipo</th>
              <th>Nome</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in listItens"
              :key="item.id"
              @click="select(item)"
              class="table-row"
            >
              <td>
                <MapElementImg :type="item.type" />
              </td>
              <td class="name-cell">
                {{ item.name }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </TemplatePopup>
</template>

<script>
import TemplatePopup from "../../../template/TemplatePopup";
import { openPopupMap } from "../../../view/map/popup";
import MapElementImg from "../../../components/extra/MapElementImg";
import {
  sendMapAction,
  clearMap,
  closePopupBus,
  sendAction
} from "../../../components/MessageBus";

export default {
  name: "MultiSelect",
  props: ["listItens", "fullScreen", "extra"],
  components: {
    TemplatePopup,
    MapElementImg,
  },
  methods: {
    select(item) {
      if (!this.extra) {
        this.exit();
        openPopupMap(
          {
            id: item.id,
            type: item.type,
            folderId: item.folderId,
          },
          null,
          item.projectId
        );
      } else {
        closePopupBus();
        sendAction(item);
      }
    },
    exit() {
      sendMapAction(clearMap);
      closePopupBus();
    },
  }
};
</script>

<style scoped>
.table-container {
  width: 100%;
  overflow-x: auto;
}

.elements-table {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
}

.elements-table th {
  background-color: #f8f9fa;
  padding: 6px;
  text-align: left;
  font-weight: 600;
  color: #2c3e50;
  border-bottom: 2px solid #e9ecef;
}

.type-column {
  width: 80px;
}

.table-row {
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.table-row:hover {
  background-color: #c3c6c9;
}

.table-row td {
  padding: 8px;
  border-bottom: 1px solid #e9ecef;
}

.type-cell {
  text-align: center;
}

.name-cell {
  color: #2c3e50;
}

@media (max-width: 768px) {
  .type-column {
    width: 60px;
  }
  
  .table-row td {
    padding: 4px;
  }
}
</style>
