<template>
  <b-col cols="4" class="order">
    <b-form-group>
      <b-form-select id="selectOrder" v-model="selectedOrder" size="sm">
        <b-form-select-option value="created">Mais recentes</b-form-select-option>
        <b-form-select-option value="name">Ordem alfabética</b-form-select-option>
      </b-form-select>
    </b-form-group>
  </b-col>
</template>

<script>
export default {
  name: "OrderByWebOrganizer",
  props: {
    value: String,
  },
  data() {
    return {
      selectedOrder: "created",
    };
  },
  watch: {
    value(newValue) {
      this.selectedOrder = newValue || "created";
    },
    selectedOrder(newValue) {
      this.$emit("input", newValue);
    },
  },
};
</script>
<style></style>
